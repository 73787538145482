@import "base/normalize";

*, *:before, *:after {
  box-sizing: border-box;
}

canvas {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

#soda-tab {
  width: 117px;
  height: 117px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  pointer-events: none;
}
